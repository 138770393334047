<template>
    <div class="home">
        <a-breadcrumb>
            <a-breadcrumb-item href="">
                <a-icon type="home" />
            </a-breadcrumb-item>
            <a-breadcrumb-item href="">
                <a-icon type="schedule" />
                <span>管理</span>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 单页内容 </a-breadcrumb-item>
        </a-breadcrumb>

        <div class="search-list-box">
            <a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
                <a-row :gutter="24">
                    <a-col :span="4">
                        <a-form-item :label="`关键词：`">
                            <a-input placeholder="请输入关键词" v-model="searchData.keywords" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item :label="`发布时间：`">
                            <a-range-picker :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }"
                                :key="pickerKey" :placeholder="['开始时间', '结束时间']" @change="handleChangeDate"
                                style="width: 120px" format="YYYY-MM-DD" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-button type="primary" @click="getList"> 查询 </a-button>
                        <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                            重置
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
            <div class="search-result-list">
                <div class="table-header-box">
                    <div class="left">
                        <h3 class="title">单页内容</h3>
                    </div>
                    <div class="right">
                        <a-button type="primary" class="marginr20" @click="showAddEdit('add')">
                            新增
                        </a-button>
                        <a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
                            详情
                        </a-button>
                        <a-button type="primary" class="marginr20" @click="deleteData">
                            删除
                        </a-button>
                    </div>
                </div>
                <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    :columns="columns" @change="handleTableChange" :pagination="{ total: total_page * 10 }"
                    :data-source="list" rowKey="art_id" size="small">
                </a-table>
            </div>
        </div>

        <a-modal v-model="addEditShow" :title="isAdd ? '新增内容' : '编辑内容'" :footer="null" :width="700">
            <a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 8 }"
                :wrapper-col="{ span: 12 }" style="margin: 0 auto;">
                <a-row :gutter="24">
                    <a-col :span="16">
                        <a-form-model-item label="标题：" prop="title">
                            <a-input v-model="info.title" />
                        </a-form-model-item>
                    </a-col>
                </a-row><a-row :gutter="24">
                    <a-col :span="16">
                        <a-form-model-item label="识别码：" prop="type">
                            <a-input v-model="info.type" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="16">
                        <a-form-model-item label="是否显示：" prop="is_check">
                            <a-radio-group v-model="info.is_check">
                                <a-radio :value="1"> 显示 </a-radio>
                                <a-radio :value="0"> 隐藏 </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="24">
                        <div id="text" style="width: 650px; height: 400px; position: relative; z-index: 1"></div>
                    </a-col>
                </a-row>
            </a-form-model>
            <div class="model-footer">
                <div class="content-btns" v-if="isAdd">
                    <a-button type="primary" class="marginr20" html-type="submit" @click="add()">
                        保存
                    </a-button>
                    <a-button class="marginr20" html-type="submit" @click="addEditShow = false">
                        取消
                    </a-button>
                </div>
                <div class="left" v-if="!isAdd">
                    <a-button type="primary" class="marginr20" html-type="submit" @click="updateNews">
                        修改
                    </a-button>
                    <a-button class="marginr20" html-type="submit" @click="addEditShow = false">
                        取消
                    </a-button>
                </div>
                <div class="right" v-if="!isAdd" :key="editIndex">
                    <a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class=""
                        html-type="submit">
                        上一条
                    </a-button>
                    <a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20"
                        html-type="submit">
                        下一条
                    </a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment';
import http from '../http';
import E from "wangeditor";
const columns = [
    {
        title: "序号",
        dataIndex: "art_id",
        width: 80
    },
    {
        title: "识别码",
        dataIndex: "type",
    },
    {
        title: "标题",
        dataIndex: "title",
    },
    {
        title: "状态",
        dataIndex: "status_txt",
        width: 120
    },
    {
        title: "发布时间",
        dataIndex: "add_time",
        width: 200
    },
];

export default {
    data() {
        return {
            expand: false,
            columns,
            catelist: [],
            searchData: {
                page: 1,
                keywords: '',
                start_date: '',
                end_date: ''
            },
            form: this.$form.createForm(this, {
                name: "advanced_search"
            }),
            info: {
                art_id: 0,
                title: "",
                type: "",
                is_check: 1,
                content: "",
            },
            rules: {
                title: [{
                    required: true,
                    message: '请输入标题',
                    trigger: 'blur'
                },],
                type: [{
                    required: true,
                    message: '请输入识别码',
                    trigger: 'blur'
                },],
            },
            list: [],
            total_page: 0,
            selectedRowKeys: [],
            selectedRowDataList: [],
            editIndex: 0,
            addEditShow: false,
            isAdd: false,
            isShow: false,
            pickerKey: Math.random()
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        moment,
        setWebText() {
            setTimeout(() => {
                this.editor = new E(document.getElementById("text"));
                this.editor.config.uploadImgMaxSize = 200 * 1024 * 1024 // 2M
                this.editor.config.customUploadImg = function (
                    resultFiles,
                    insertImgFn
                ) {
                    let file = resultFiles[0];
                    http({
                        method: "post",
                        url: "/api.config.upload.image/",
                        data: {
                            isFile: true,
                            file: file
                        },
                    }).then((res) => {
                        console.log(res)
                        if (res.code == '200') {
                            insertImgFn(res.data);
                        }
                    });
                };
                this.editor.create();
                this.editor.txt.html(this.info.content);
                this.isShow = true
            }, 500);
        },
        handleSearch(e) {
            e.preventDefault();
            this.form.validateFields((error, values) => {
            });
        },
        disabledDate(current) {
            return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
        },
        handleReset() {
            this.pickerKey = Math.random()
            this.searchData = {
                page: 1,
                keywords: '',
                start_date: '',
                end_date: ''
            }
            this.getList();
        },
        isEditBtn(type) {
            if (type == 'prev') {
                if (this.editIndex == 0) {
                    return true
                }
                if (this.selectedRowDataList == 1) {
                    return true
                }
                if (this.editIndex >= 1) {
                    return false
                }
            } else if (type == 'next') {
                if ((this.selectedRowDataList.length - 1) == this.editIndex) {
                    return true
                }
            }
        },
        showAddEdit(type) {
            if (this.isShow == false) {
                this.setWebText()
            }
            if (type == 'add') {
                this.info = {
                    art_id: 0,
                    title: '',
                    type: '',
                    is_check: 0,
                    content: '',
                }
                this.isAdd = true
            } else if (type == 'edit') {
                if (this.selectedRowKeys.length == 0) {
                    this.$message.warning('请先选择内容');
                    return
                }
                let selectDatas = []
                this.list.forEach((v) => {
                    if (this.selectedRowKeys.includes(v.art_id)) {
                        selectDatas.push(v)
                    }
                })
                this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
                this.editIndex = 0
                this.getDetail()
                this.isAdd = false
            }
            this.addEditShow = true
        },
        getDetail() {
            this.detailLoading = true
            http({
                method: 'post',
                url: '/api.art.detail/',
                data: {
                    art_id: this.selectedRowDataList[this.editIndex].art_id
                }
            }).then((res) => {
                this.info = res.data
                this.editor.txt.html(this.info.content);
                this.detailLoading = false
            })
        },
        handleChangeDate(date) {
            this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
            this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
        },
        handleTableChange(pagination, filters, sorter) {
            this.searchData.page = pagination.current
            this.getList();
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        getList() {
            http({
                method: 'post',
                url: '/api.art.list/',
                data: {
                    ...this.searchData,
                }
            }).then((res) => {
                this.list = res.data.list
                this.total_page = res.data.total_page
            })
        },
        deleteData() {
            if (this.selectedRowKeys.length == 0) {
                this.$message.warning('请先选择内容');
                return
            }
            this.$confirm({
                title: '是否删除选中内容?',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    let selectDatas = []
                    this.list.forEach((v) => {
                        if (this.selectedRowKeys.includes(v.art_id)) {
                            selectDatas.push(v.art_id)
                        }
                    })
                    http({
                        method: 'post',
                        url: '/api.art.del/',
                        data: {
                            art_id: selectDatas.join(),
                        }
                    }).then((res) => {
                        if (res.code == '200') {
                            this.$message.success('删除内容成功');
                            this.getList()
                        } else {
                            this.$message.error(res.data);
                        }
                    })
                }
            });
        },
        add(type) {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    http({
                        method: 'post',
                        url: '/api.art.add/',
                        data: {
                            ...this.info,
                            content: this.editor.txt.html() || "",
                        }
                    }).then((res) => {
                        if (res.code == '200') {
                            if (!type) {
                                this.addEditShow = false
                            }
                            this.$message.success(type == 'edit' ? '修改内容成功' : '添加内容成功');
                            this.getList()
                            this.addEditShow = false
                        } else {
                            this.$message.error(res.data);
                        }
                    })
                } else {
                    this.$message.warning('请检查是否已经输入必填项!');
                    return false;
                }
            });
        },
        updateNews() {
            this.add('edit')
        }
    },
};
</script>
<style>
.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

.search-list-box .ant-form {
    max-width: none;
}

.search-list-box .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}
</style>
